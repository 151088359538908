<template>
    <div class="wrap">
        <common-header></common-header>
        <div class="product" :style="'background: url(' + banner + ') no-repeat top center;'">
            <div class="main">
                <div class="right-main">
                    <div v-if="pTitle" class="breadcrumb">
                        <a href="javascript:;" @click="$router.back()">星星推荐>{{pTitle}}</a><span v-if="title">>{{title}}</span>
                    </div>
                    <div v-if="goodsList.length" class="product-list">
                        <div class="list-box">
                            <router-link v-for="item in goodsList" class="product-item"
                                :to="'/productdetail?id=' + item.goodsId">
                                <img :src="item.goodsImg" alt="">
                                <h4>{{item.goodsType}}</h4>
                                <p class="txt">{{item.goodsTitle}}</p>
                                <div class="goods-tags">
                                    <span>{{item.deptName}}</span>
                                    <span v-if="item.recommendNew == 1" class="new">新品</span>
                                </div>
                                <p class="price">¥{{item.goodsPrice.toFixed(2)}}</p>
                            </router-link>
                        </div>
                        <div class="page-wrap">
                            <pagination v-show="total>0" :total="total" background
                                layout="prev, pager, next, total, jumper" :page.sync="listQuery.current"
                                :limit.sync="listQuery.size" @pagination="getList" />
                        </div>
                    </div>
                    <div v-else class="no-goods">
                        暂无商品
                    </div>
                </div>
            </div>
        </div>
        <black-footer></black-footer>
    </div>
</template>
<script>
    import CommonHeader from '@/components/CommonHeader';
    import BlackFooter from '@/components/BlackFooter';
    import Pagination from '@/components/Pagination'
    export default {
        name: 'Recommend',
        components: {
            CommonHeader,
            BlackFooter,
            Pagination
        },
        data() {
            return {
                id: this.$route.query.id,
                activityId: this.$route.query.activityId,
                pTitle: this.$route.query.ptitle,
                title: this.$route.query.title,
                banner: '',
                goodsList: [],
                listQuery: {
                    current: 1,
                    size: 15,
                },
            }
        },
        mounted() {
            this.pageInit();
            this.getList();
        },
        methods: {
            pageInit() {
                if (this.activityId) {
                    this.$api.getActivityDetail(this.activityId).then(res => {
                        let { data, success } = res.data;
                        if (success) {
                            this.banner = data.banner;
                        }
                    });
                }
                if (this.id) {
                    this.$api.getCateDetail(this.id).then(res => {
                        let { data, success } = res.data;
                        if (success) {
                            this.banner = data.banner;
                        }
                    });
                }
            },
            getList() {
                let opt = { ...this.listQuery, goodsCateId: this.id };
                let ajaxList = this.$api.getGoodsList;
                if (this.activityId) {
                    ajaxList = this.$api.getActivityGoods;
                    opt.goodsCateId = null;
                    opt.activityId = this.activityId;
                }
                ajaxList(opt).then(res => {
                    let { data, success } = res.data;
                    if (success) {
                        this.goodsList = data.rows;
                        this.total = data.total;
                    }
                });
            },
        }
    }
</script>
<style scoped>
    .product {
        background-size: 1920px 400px !important;
    }

    .right-main {
        padding-top: 432px;
        min-height: calc(100vh - 100px);
    }
    .breadcrumb {
        line-height: 20px;
        font-size: 20px;
        font-weight: 700;
        color: #444;
        margin-bottom: 29px;
    }

    .product-list {
        padding: 0 0 32px;
    }

    .list-box {
        margin-bottom: 22px;
    }
    .list-box::after{
        content: "";
        display: block;
        overflow: hidden;
        height: 0;
        clear: both;
    }

    .product-item {
        line-height: 18px;
        font-size: 18px;
        font-weight: 400;
        color: #444;
        width: 270px;
        float: left;
        margin-right: 10px;
        border: 1px solid #f6f6f6;
        margin-bottom: 10px;
    }

    .product-item:hover {
        box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.16);
    }

    .product-item:nth-child(5n) {
        margin-right: 0;
    }

    .product-item img {
        display: block;
        width: 268px;
        height: 268px;
    }

    .product-item h4 {
        margin: 16px 12px 8px;
        line-height: 17px;
        font-size: 16px;
        font-weight: 700;
        color: #444;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .txt {
        line-height: 16px;
        font-size: 16px;
        color: #444;
        margin: 0 12px 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .price {
        line-height: 18px;
        font-size: 18px;
        font-weight: 700;
        color: #ff3838;
        margin: 8px 12px 16px;
    }

    .no-goods {
        line-height: 20px;
        font-size: 20px;
        text-align: center;
        color: #666;
        padding-top: 43px;
    }
</style>