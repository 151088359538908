<template>
	<div class="">
		<div class="search-wrap">
			<label for="">关键词</label>
			<input class="input-sn" type="text" placeholder="搜索产品型号" v-model="keyword" />
			<label for="">容量区间</label>
			<input class="input-min" type="text" placeholder="最低" v-model="literMinGt" />
			<input class="input-max" type="text" placeholder="最高" v-model="literMaxLt" />
			<button class="search-btn" @click="searchGoods">搜索</button>
		</div>
		<div v-if="areaList.length" class="product-list">
			<div v-for="item in areaList">
				<h3 v-if="item.children && item.children.length" class="h3-title"><a class="more" v-if="item.type != 3"
						href="javascript:;" @click="jumpGoodsAll(item)">全部商品</a><span>{{item.title}}</span></h3>
				<div class="list-box">
					<div v-for="child in item.children" class="product-item" @click="jumpPage(child, item)">
						<img :src="child.img" alt="">
						<h4>{{child.title}}</h4>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="no-goods">
			暂无商品
		</div>
	</div>
</template>
<script>
	export default {
		name: 'Product',
		props: [
			'menu'
		],
		components: {
		},
		data() {
			return {
				isScroll: false,
				id: this.$route.params.pid,
				areaList: [],

				keyword: '',
				literMinGt: '',
				literMaxLt: ''
			}
		},
		mounted() {
			this.pageInit();
		},
		beforeRouteLeave(to, from, next) {
			let position = window.scrollY;
			localStorage.productTop = position;
			next();
		},
		beforeRouteEnter(to, from, next) {
			let pid = to.params.pid;
			let id = from.params.pid;
			let name = from.name;
			if (name == 'ProductList' && id == pid) {
				next(vm => {
					vm.isScroll = true;
				});
			}
			else if(name == 'Recommend'){
				next(vm => {
					vm.isScroll = true;
				});
			}
			else {
				next();
			}
		},
		methods: {
			pageInit() {
				let opt = {
					type: '1,3',
					cateId1: this.id
				};
				if (!this.id) {
					opt.cateId1 = this.menu.goodsCateId;
				}
				this.$api.getCateData(opt).then(res => {
					let { data, success } = res.data;
					if (success) {
						this.areaList = data[0].children;
						if (this.isScroll) {
							this.isScroll = false;
							this.$nextTick(() => {
								let position = localStorage.productTop || 0;
								window.scroll(0, position);
								localStorage.productTop = 0;
							})
						}
					}
				});
			},
			searchGoods() {
				let pid = this.id || this.menu.goodsCateId;
				this.$router.push({
					path: '/product/' + pid + '/goods?keyword=' + this.keyword + '&literMinGt=' + this.literMinGt + '&literMaxLt=' + this.literMaxLt
				});
			},
			jumpPage(data, parent) {
				if (data.url) {
					window.open(data.url, '_blank');
				}
				else {
					//星星推荐跳转列表
					if (data.type == 3) {
						this.$router.push({
							path: '/recommend?id=' + data.goodsCateId + '&ptitle=' + parent.title + '&title=' + data.title
						});
					}
					else {
						let pid = this.id || this.menu.goodsCateId;
						this.$router.push({
							path: '/product/' + pid + '/goods?id=' + data.goodsCateId + '&ptitle=' + parent.title + '&title=' + data.title
						});
					}
				}
			},
			jumpGoodsAll(data) {
				let pid = this.id || this.menu.goodsCateId;
				this.$router.push({
					path: '/product/' + pid + '/goods?id=' + data.goodsCateId + '&ptitle=' + data.title
				});
			},
		}
	}
</script>
<style scoped>
	.search-wrap {
		width: 940px;
		height: 64px;
		background: #f6f6f6;
		border-radius: 8px;
		margin: 0 auto;
		padding: 12px 24px;
		font-size: 16px;
		color: #666;
		line-height: 40px;
	}

	.search-wrap label {
		margin-right: 16px;
	}

	.search-wrap input {
		margin-right: 48px;
		height: 40px;
		background: #ffffff;
		border: 1px solid #c7c7c7;
	}

	.search-wrap input.input-sn {
		width: 368px;
		padding-left: 38px;
		background: #fff url(~@/assets/icon_search.png) no-repeat 16px center;
	}

	.search-wrap input.input-min {
		width: 102px;
		margin-right: 21px;
		padding-left: 16px;
	}

	.search-wrap input.input-max {
		width: 102px;
		margin-right: 0;
		padding-left: 16px;
	}

	.search-btn {
		float: right;
		width: 62px;
		height: 40px;
		background: #ffffff;
		border: 1px solid #34b8ff;
		color: #34b8ff;
		cursor: pointer;
	}

	.product-list {
		padding: 48px 0 32px;
	}

	.h3-title {
		line-height: 20px;
		font-weight: 700;
		color: #444;
		margin-bottom: 14px;
		font-size: 24px;
		line-height: 1;
		border-bottom: 1px solid #c7c7c7;
	}

	.h3-title span {
		display: inline-block;
		padding-bottom: 8px;
	}

	.more {
		float: right;
		font-style: normal;
		font-size: 16px;
		font-weight: 400;
		padding-right: 16px;
		background: url(~@/assets/icon_arrow_r.png) no-repeat right center;
	}

	.list-box {
		margin-bottom: 22px;
	}
    .list-box::after{
        content: "";
        display: block;
        overflow: hidden;
        height: 0;
        clear: both;
    }

	.product-item {
		line-height: 18px;
		font-size: 18px;
		font-weight: 400;
		text-align: center;
		color: #444;
		width: 275px;
		float: left;
		margin-right: 32px;
		margin-bottom: 10px;
		cursor: pointer;
		padding-bottom: 20px;
	}
    .product-item:hover {
        box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.16);
    }

	.product-item:nth-child(4n) {
		margin-right: 0;
	}

	.product-item img {
		display: block;
		width: 275px;
		height: 275px;
		border-radius: 8px;
	}

	.product-item h4 {
		margin-top: 16px;
	}

	.no-goods {
		line-height: 20px;
		font-size: 20px;
		text-align: center;
		color: #666;
		padding-top: 43px;
	}
</style>