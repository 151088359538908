<template>
    <div class="wrap">
        <common-header></common-header>
        <div class="news" :style="'background: url(' + banner + ') no-repeat top center;'">
            <div class="main news-detail">
                <div class="breadcrumb">
                    新闻中心>新闻详情
                </div>
                <div class="content">
                    <h2>{{detail.title}}</h2>
                    <div class="source">来源{{detail.source}}<span>发表于{{detail.addTime}}</span></div>
                    <div class="editor-content-view">
                        <div v-html="detail.content"></div>
                    </div>
                </div>
            </div>
        </div>
        <black-footer></black-footer>
    </div>
</template>
<script>
    import CommonHeader from '@/components/CommonHeader';
    import BlackFooter from '@/components/BlackFooter';
    export default {
        name: 'NewsDetail',
        components: {
            CommonHeader,
            BlackFooter,
        },
        data() {
            return {
                id: this.$route.query.id,
                detail: {},
                banner: '',
            }
        },
        mounted() {
            this.pageInit();
        },
        methods: {
            pageInit() {
                this.$api.getArticleDetail(this.id).then(res => {
                    let { data, success } = res.data;
                    if (success) {
                        this.detail = data;
                    }
                });
                
                this.$api.getActivityData('PCBANNER').then(res => {
                    let { data, success } = res.data;
                    if (success) {
                        this.banner = data.filter(item => item.model == 'BANNER_4')[0].img;
                    }
                });
            },
        }
    }
</script>
<style scoped>
    .news-detail {
        padding-top: 432px;
    }

    .breadcrumb {
        line-height: 20px;
        font-size: 20px;
        font-weight: 700;
        color: #444;
        margin-bottom: 45px;
    }

    .content {
        padding-bottom: 36px;
        font-size: 16px;
        color: #444;
        margin: 0 auto;
    }

    .content h2 {
        line-height: 32px;
        font-size: 32px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 34px;
    }

    .source {
        font-size: 16px;
        line-height: 16px;
        color: #c7c7c7;
        margin-bottom: 48px;
        text-align: center;
    }

    .source span {
        margin-left: 18px;
    }

    .content img {
        max-width: 100%;
    }
</style>
<style>
    .editor-content-view {
        padding: 0 10px;
        margin-top: 20px;
        overflow-x: auto;
    }

    .editor-content-view p,
    .editor-content-view li {
        white-space: pre-wrap;
        /* 保留空格 */
    }

    .editor-content-view blockquote {
        border-left: 8px solid #d0e5f2;
        padding: 10px 10px;
        margin: 10px 0;
        background-color: #f1f1f1;
    }

    .editor-content-view code {
        font-family: monospace;
        background-color: #eee;
        padding: 3px;
        border-radius: 3px;
    }

    .editor-content-view pre>code {
        display: block;
        padding: 10px;
    }

    .editor-content-view table {
        border-collapse: collapse;
    }

    .editor-content-view td,
    .editor-content-view th {
        border: 1px solid #ccc;
        min-width: 50px;
        height: 20px;
    }

    .editor-content-view th {
        background-color: #f1f1f1;
    }

    .editor-content-view ul,
    .editor-content-view ol {
        padding-left: 20px;
        list-style-type: decimal;
    }

    .editor-content-view ul {
        list-style-type: disc;
    }

    .editor-content-view li {
        line-height: inherit;
        margin: 10px 0;
        line-height: 36px;
    }

    .editor-content-view input[type="checkbox"] {
        margin-right: 5px;
    }
</style>