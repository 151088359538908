<template>
    <div class="">
        <div class="search-wrap">
            <label for="">关键词</label>
            <input class="input-sn" type="text" placeholder="搜索产品型号" v-model="search.keyword" />
            <label for="">容量区间</label>
            <input class="input-min" type="text" placeholder="最低" v-model="search.literMinGt" />
            <input class="input-max" type="text" placeholder="最高" v-model="search.literMaxLt" />
            <button class="search-btn" @click="searchGoods">搜索</button>
        </div>
        <div v-if="pTitle" class="breadcrumb">
            <a href="javascript:;" @click="$router.back()">{{pTitle}}</a> <span v-if="title">> {{title}}</span><span v-else>> 全部商品</span>
        </div>
        <div v-if="goodsList.length" class="goods-list">
            <div class="list-box">
                <router-link v-for="goods in goodsList" class="goods-item" :to="'/productdetail?id=' + goods.goodsId">
                    <img :src="goods.goodsImg" alt="">
                    <h4>{{goods.goodsType}}</h4>
                    <p class="txt">{{goods.goodsTitle}}</p>
                    <div class="goods-tags">
                        <span>{{goods.deptName}}</span>
                        <span v-if="goods.recommendNew == 1" class="new">新品</span>
                    </div>
                    <p class="price">¥{{goods.goodsPrice.toFixed(2)}}</p>
                </router-link>
            </div>
            <div class="page-wrap">
                <pagination v-show="total>0" :total="total" background layout="prev, pager, next, total, jumper"
                    :page.sync="listQuery.current" :limit.sync="listQuery.size" @pagination="getList" />
            </div>
        </div>
        <div v-else class="no-goods">
            暂无商品
        </div>
    </div>
</template>
<script>
    import Pagination from '@/components/Pagination'
    export default {
        name: 'ProductList',
        components: {
            Pagination
        },
        data() {
            return {
                pid: this.$route.params.pid,
                cateList: [],
                goodsCateId: this.$route.query.id,
                pTitle: this.$route.query.ptitle,
                title: this.$route.query.title,
                listQuery: {
                    current: 1,
                    size: 15,
                },
                goodsList: [],
                total: 0,

                search: {
                    keyword: this.$route.query.keyword,
                    literMinGt: this.$route.query.literMinGt,
                    literMaxLt: this.$route.query.literMaxLt,
                }
            }
        },
        watch: {
            $route(to, from) {
                this.initParams();
                this.getList();
            }
        },
        mounted() {
            this.pageInit();
        },
        methods: {
            pageInit() {
                this.getList();
            },
            getList() {
                this.$api.getGoodsList({ ...this.listQuery, goodsCateId: this.goodsCateId, ...this.search }).then(res => {
                    let { data, success } = res.data;
                    if (success) {
                        this.goodsList = data.rows;
                        this.total = data.total;
                    }
                });
            },
            initParams() {
                this.listQuery = {
                    current: 1,
                    size: 15,
                };
                this.pid = this.$route.params.pid;
                this.goodsCateId = this.$route.query.id;
                this.pTitle = this.$route.query.ptitle;
                this.title = this.$route.query.title;
                this.search = {
                    keyword: this.$route.query.keyword,
                    literMinGt: this.$route.query.literMinGt,
                    literMaxLt: this.$route.query.literMaxLt,
                };
                this.goodsList = [];
            },
            searchGoods() {
                this.$router.push({
                    path: '/product/' + this.pid + '/goods',
                    query: {
                        keyword: this.search.keyword,
                        literMinGt: this.search.literMinGt,
                        literMaxLt: this.search.literMaxLt,
                    }
                }, () => { });
            },
        }
    }
</script>
<style scoped>
    .search-wrap {
        width: 940px;
        height: 64px;
        background: #f6f6f6;
        border-radius: 8px;
        margin: 0 auto 48px;
        padding: 12px 24px;
        font-size: 16px;
        color: #666;
        line-height: 40px;
    }

    .search-wrap label {
        margin-right: 16px;
    }

    .search-wrap input {
        margin-right: 48px;
        height: 40px;
        background: #ffffff;
        border: 1px solid #c7c7c7;
    }

    .search-wrap input.input-sn {
        width: 368px;
        padding-left: 38px;
        background: #fff url(~@/assets/icon_search.png) no-repeat 16px center;
    }

    .search-wrap input.input-min {
        width: 102px;
        margin-right: 21px;
        padding-left: 16px;
    }

    .search-wrap input.input-max {
        width: 102px;
        margin-right: 0;
        padding-left: 16px;
    }

    .search-btn {
        float: right;
        width: 62px;
        height: 40px;
        background: #ffffff;
        border: 1px solid #34b8ff;
		color: #34b8ff;
        cursor: pointer;
    }

    .breadcrumb {
        line-height: 20px;
        font-size: 20px;
        font-weight: 700;
        color: #444;
        margin-bottom: 29px;
    }

    .goods-list {
        padding: 0 0 32px;
    }

    .list-box {
        margin-bottom: 22px;
    }
    .list-box::after{
        content: "";
        display: block;
        overflow: hidden;
        height: 0;
        clear: both;
    }

    .goods-item {
        line-height: 18px;
        font-size: 18px;
        font-weight: 400;
        color: #444;
        width: 231px;
        float: left;
        margin-right: 10px;
        border: 1px solid #f6f6f6;
        margin-bottom: 10px;
    }

    .goods-item:hover {
        box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.16);
    }

    .goods-item:nth-child(5n) {
        margin-right: 0;
    }

    .goods-item img {
        display: block;
        width: 229px;
        height: 229px;
    }

    .goods-item h4 {
        margin: 16px 12px 8px;
        line-height: 17px;
        font-size: 16px;
        font-weight: 700;
        color: #444;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .txt {
        line-height: 16px;
        font-size: 16px;
        color: #444;
        margin: 0 12px 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .price {
        line-height: 18px;
        font-size: 18px;
        font-weight: 700;
        color: #ff3838;
        margin: 8px 12px 16px;
    }

    .no-goods {
        line-height: 20px;
        font-size: 20px;
        text-align: center;
        color: #666;
        padding-top: 43px;
    }
</style>