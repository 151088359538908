<template>
  <div class="header-wrap">
    <div class="header">
        <router-link to="/"><img class="logo" src="@/assets/icon_logo.png" alt="星星冷链" /></router-link>
        <div class="header-main">
          <ul class="nav">
            <li v-for="(menu, i) in menuList">
              <router-link :class="{active: getActive(menu.url)}" :to="menu.url">{{menu.name}}</router-link>
            </li>
          </ul>
        </div>
    </div>
    <!-- <div class="blank"></div> -->
  </div>
</template>
  
  <script>
  export default {
    props: {
    },
    data(){
      return {
        menuList: [
          {
            name: '首页',
            url: '/'
          },
          {
            name: '商品货架',
            url: '/product'
          },
          {
            name: '品宣视频',
            url: '/video'
          },
          {
            name: '新品推荐',
            url: '/newrecommend'
          },
          {
            name: '新闻中心',
            url: '/news'
          }
        ],
      }
    },
    mounted(){
    },
    methods: {
      getActive(url){
        let path = this.$route.path;
        if(url === path){
          return true;
        }
        else if(path.indexOf(url) > -1 && url !== '/'){
          return true;
        }
        return false;
      }
    }
  }
  </script>
  <style scoped>
  .header{
    background: #f3f3f3;
    height: 100px;
    position: relative;
    /* position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999; */
  }
  .blank{
    height: 100px;
  }
  .logo{
    position: absolute;
    max-width: 487px;
    width: 28vw;
    left: 24px;
    top: 50%;
    transform: translateY(-50%);
    display: block;
  }
  .header-main{
    width: 500px;
    margin: 0 auto;
  }
  .nav{
    display: flex;
    display: -ms-flexbox;
  }
  .nav li{
    position: relative;
    text-align: center;
  }
  .nav a{
    width: 100px;
    height: 100px;
    line-height: 100px;
    font-size: 18px;
    color: #444;
    display: block;
  }
  .nav a:hover, .nav a.active{
    background: #34b8ff;
    color: #fff;
  }

  </style>
  