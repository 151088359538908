<template>
  <div class="wrap">
    <common-header></common-header>
    <div class="product" :style="'background: url(' + banner + ') no-repeat top center;'">
      <div class="main">
        <div class="right-main">
          <div v-if="newsList.length" class="product-list">
            <div class="list-box">
              <router-link v-for="item in newsList" class="product-item" :to="'/newsdetail?id=' + item.articleId">
                <img :src="item.img" alt="">
                <h4>{{item.title}}</h4>
                <p class="txt">来源于{{item.source}}<span>{{item.addTime}}</span></p>
                <p class="desc">{{item.content}}</p>
              </router-link>
            </div>
            <div class="page-wrap">
              <pagination v-show="total>0" :total="total" background layout="prev, pager, next, total, jumper" :page.sync="listQuery.current" :limit.sync="listQuery.size" @pagination="getList" />
            </div>
          </div>
          <div v-else class="no-goods">
            暂无新闻
          </div>
        </div>
      </div>
    </div>
    <black-footer></black-footer>
  </div>
</template>
<script>
  import CommonHeader from '@/components/CommonHeader';
  import BlackFooter from '@/components/BlackFooter';
	import Pagination from '@/components/Pagination'
  export default {
    name: 'News',
    components: {
      CommonHeader,
      BlackFooter,
      Pagination
    },
    data() {
      return {
        banner: '',
        newsList: [],
        listQuery: {
          current: 1,
          size: 15,
        },
      }
    },
    mounted() {
      this.getList();
    },
    methods: {
      getList() {
        this.$api.getArticleList({ ...this.listQuery }).then(res => {
          let { data, success } = res.data;
          if (success) {
            this.newsList = data.rows;
            this.total = data.total;
          }
        });

        this.$api.getActivityData('PCBANNER').then(res => {
          let { data, success } = res.data;
          if (success) {
            this.banner = data.filter(item => item.model == 'BANNER_4')[0].img;
          }
        });
      },

    }
  }
</script>
<style scoped>
  .right-main {
    padding-top: 460px;
    min-height: calc(100vh - 100px);
  }

  .product-list {
    padding: 0 0 32px;
  }

  .list-box {
    margin-bottom: 22px;
  }

  .product-item {
    line-height: 18px;
    font-size: 18px;
    font-weight: 400;
    color: #444;
    margin-bottom: 64px;
    overflow: hidden;
    display: block;
  }

  .product-item img {
    float: left;
    width: 338px;
    height: 177px;
    margin-right: 32px;
  }

  .product-item h4 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 32px;
    line-height: 20px;
    font-size: 20px;
    font-weight: 700;
    color: #444;
  }

  .txt {
    line-height: 16px;
    font-size: 16px;
    color: #666;
    margin-bottom: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .txt span {
    margin-left: 32px;
    color: #c7c7c7;
  }

  .desc {
    line-height: 19px;
    font-size: 16px;
    color: #444;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .no-goods {
    line-height: 20px;
    font-size: 20px;
    text-align: center;
    color: #666;
    padding-top: 43px;
  }
</style>