import axios from '@/utils/http';
import qs from 'qs';

//请求示例
// const get1 = data => axios.get(url, { params: data });
// const post1 = data => axios.post(url, qs.stringify(data));
// const post2 = data => axios.post(url, data);
// const post3 = data => axios.post(url, data, {headers: {'Content-Type': 'application/json'}});

let xygUrl = '/xygms-pc'
if(process.env.NODE_ENV === 'development'){
    xygUrl = ''
}

const getActivityData = type => axios.get(xygUrl + '/api/activity/p/' + type);
const getActivityDetail = id => axios.get(xygUrl + '/api/activity/p/info/' + id);
const getActivityGoods = data => axios.get(xygUrl + '/api/activity/p/goodsList', { params: data });

const getCateData = data => axios.get(xygUrl + '/api/cate/p/tree/' + data.type, {params: data});
const getCateDetail = id => axios.get(xygUrl + '/api/cate/p/' + id);
const getGoodsList = data => axios.get(xygUrl + '/api/goods/p', { params: data });
const getGoodsDetail = id => axios.get(xygUrl + '/api/goods/p/' + id);

const getVideoList = data => axios.get(xygUrl + '/api/video/p', { params: data });

const getArticleList = data => axios.get(xygUrl + '/api/article/p', { params: data });
const getArticleDetail = id => axios.get(xygUrl + '/api/article/p/' + id);

export default {
    getActivityData,
    getActivityDetail,
    getActivityGoods,

    getCateData,
    getCateDetail,
    getGoodsList,
    getGoodsDetail,

    getVideoList,

    getArticleList,
    getArticleDetail,
}