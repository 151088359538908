<template>
    <div class="wrap">
        <common-header></common-header>
        <div class="main">
            <div v-if="detail" class="product-main">
                <div class="product-imgs">
                    <div class="img-main">
                        <img v-if="currentType == 1" :src="currentImg" alt="">
                        <video v-if="currentType == 2 && currentUrl" :src="currentUrl" controls></video>
                        <div v-if="currentType == 3" id="player"></div>
                    </div>
                    <div class="img-list">
                        <ul>
                            <li v-for="(item, idx) in detail.media" v-if="idx < 6" @click="playMedia(item)">
                                <img v-if="item.type == 1" :src="item.value" alt="">
                                <template v-else>
                                    <img :src="detail.media[1].value" alt="">
                                    <a class="play" href="javascript:;"></a>
                                </template>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="product-info">
                    <h2><span class="new-tag" v-if="detail.recommendNew == 1">新品</span><span>{{detail.goodsType}}
                            {{detail.goodsTitle}}</span></h2>
                    <div class="price">
                        <label>商品价格</label>
                        <span>¥ {{currentPrice.toFixed(2)}}</span>
                    </div>
                    <div class="specs" v-for="item in goodsModel">
                        <label for="">{{item.name}}</label>
                        <div class="tags">
                            <span v-for="(model, idx) in item.data" :class="{active: item.idx == idx}"
                                @click="chooseModel(item, idx)">{{model}}</span>
                        </div>
                    </div>
                    <div class="dept">
                        <label for="">生产厂家</label>
                        <span>{{detail.deptName}}</span>
                    </div>
                </div>
            </div>
            <div v-if="detail" class="product-detail">
                <el-tabs v-model="activeName" type="card">
                    <el-tab-pane label="产品参数" name="first">
                        <div class="content">
                            <div class="params">
                                <ul>
                                    <li v-for="param in detail.goodsTag" :title="param.tagsData">
                                        {{param.tagsName}}：{{param.tagsData}}</li>
                                </ul>
                            </div>
                            <div class="detail" v-html="detail.goodsInfo1"></div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="服务保障" name="second">
                        <div class="content">
                            <div class="detail" v-html="detail.goodsInfo2"></div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <black-footer></black-footer>
    </div>
</template>
<script>
    import CommonHeader from '@/components/CommonHeader';
    import BlackFooter from '@/components/BlackFooter';
    export default {
        name: 'ProductDetail',
        components: {
            CommonHeader,
            BlackFooter,
        },
        data() {
            return {
                id: this.$route.query.id,
                detail: {},
                currentPrice: 0,
                activeName: 'first',
                currentUrl: '',
                currentImg: '',
                currentType: '',
                goodsModel: []
            }
        },
        mounted() {
            this.pageInit();
        },
        methods: {
            pageInit() {
                this.$api.getGoodsDetail(this.id).then(res => {
                    let { data, success } = res.data;
                    if (success) {
                        this.detail = data.data;
                        this.currentPrice = this.detail.goodsPrice;
                        let media = this.detail.media[0];
                        this.currentType = media.type;
                        if (media.type == 1) {
                            this.currentImg = media.value;
                        }
                        else if (media.type == 2) {
                            this.currentUrl = media.value;
                        }
                        else {
                            this.playblw(media.value);
                        }
                        this.fixModel();
                    }
                })
            },
            fixModel() {
                let goodsModel = this.detail.goodsModel[0];
                let modelList = [
                    {
                        name: goodsModel.modelName1,
                        data: [],
                        idx: -1
                    },
                    {
                        name: goodsModel.modelName2,
                        data: [],
                        idx: -1
                    }
                ];
                this.detail.goodsModel.forEach(ele => {
                    if (modelList[0].data.indexOf(ele.modelData1) == -1) {
                        modelList[0].data.push(ele.modelData1);
                    }
                    if (modelList[1].data.indexOf(ele.modelData2) == -1) {
                        modelList[1].data.push(ele.modelData2);
                    }
                });
                this.goodsModel = modelList;
            },
            playMedia(item) {
                this.currentType = item.type;
                if (item.type == 1) {
                    this.currentImg = item.value;
                }
                else if (item.type == 2) {
                    this.currentUrl = item.value;
                }
                else {
                    this.playblw(item.value);
                }
            },
            playblw(vid) {
                this.$nextTick(() => {
                    this.player && this.player.destroy();
                    this.player = polyvPlayer({
                        wrap: "#player",
                        width: 440,
                        height: 440,
                        autoplay: false,
                        vid
                    });
                })
            },
            chooseModel(item, idx) {
                if (item.idx == idx) {
                    item.idx = -1;
                    this.currentPrice = this.detail.goodsPrice;
                    let media = this.detail.media[0];
                    this.currentType = media.type;
                    if (media.type == 1) {
                        this.currentImg = media.value;
                    }
                    else if (media.type == 2) {
                        this.currentUrl = media.value;
                    }
                    else {
                        this.playblw(media.value);
                    }
                    return;
                }
                item.idx = idx;
                let key = '';
                let data = {
                    modelData1: '',
                    modelData2: ''
                }
                this.goodsModel.forEach((ele, i) => {
                    data['modelData' + (i + 1)] = ele.data[ele.idx];
                });
                this.detail.goodsModel.forEach(ele => {
                    if (ele.modelData1 == data.modelData1 && ele.modelData2 == data.modelData2) {
                        this.currentType = 1;
                        this.currentImg = ele.modelImg;
                        this.currentPrice = ele.price;
                    }
                });
            }
        }
    }
</script>
<style scoped>
    .product-main {
        padding-top: 32px;
        overflow: hidden;
        margin-bottom: 62px;
    }

    .product-imgs {
        width: 440px;
        float: left;
    }

    .img-main {
        position: relative;
        width: 440px;
        height: 440px;
        background: #000;
    }

    .img-main img {
        width: 100%;
        height: 100%;
    }

    .img-main video {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: #000;
    }

    .img-list {
        overflow: hidden;
        margin-top: 14px;
    }

    .img-list li {
        float: left;
        width: 67px;
        height: 67px;
        position: relative;
        margin-right: 7px;
        cursor: pointer;
    }

    .img-list li:last-child {
        margin-right: 0;
    }

    .img-list li img,
    .img-list li video {
        width: 100%;
        height: 100%;
        background: #000;
    }

    .play {
        background: url(~@/assets/icon_play_s.png) no-repeat;
        width: 24px;
        height: 24px;
        display: block;
        position: absolute;
        left: 22px;
        top: 22px;
    }

    .product-info {
        width: 950px;
        float: left;
        padding-left: 55px;
    }

    .product-info h2 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 24px;
        font-size: 24px;
        font-weight: 700;
        color: #444;
        margin-bottom: 36px;
    }

    .product-info h2 span {
        display: inline-block;
        vertical-align: middle;
    }

    .new-tag {
        font-size: 10px;
        text-align: center;
        color: #ff3838;
        line-height: 16px;
        width: 32px;
        height: 18px;
        background: #fff;
        border: 1px solid #ff3838;
        margin-right: 12px;
    }

    .price {
        width: 720px;
        height: 76px;
        background: #f6f6f6;
        margin-bottom: 16px;
    }

    .price label,
    .specs label,
    .dept label {
        width: 72px;
        line-height: 76px;
        font-size: 18px;
        color: #666;
        margin: 0 24px;
        float: left;
    }

    .price span {
        line-height: 76px;
        font-size: 24px;
        font-weight: 700;
        color: #ff3838;
    }

    .specs {
        width: 720px;
        overflow: hidden;
        margin-bottom: 8px;
    }

    .specs label, .dept label {
        line-height: 48px;
    }
    .dept{
        line-height: 48px;
        color: #666;
    }

    .tags {
        padding-left: 120px;
    }

    .tags span {
        display: inline-block;
        line-height: 46px;
        padding: 0 18px;
        background: #f6f6f6;
        border: 1px solid #d6d6d6;
        margin-bottom: 16px;
        margin-right: 16px;
        cursor: pointer;
        font-size: 18px;
    }

    .tags span.active {
        background: #ff3838;
        border: 1px solid #ff3838;
        color: #fff;
    }

    .content {
        padding-bottom: 36px;
    }

    .params {
        overflow: hidden;
        padding: 52px 0 34px 34px;
        border-bottom: 1px solid #e0e3e8;
        margin-bottom: 45px;
    }

    .params li {
        float: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 308px;
        margin: 12px;
        font-size: 18px;
        line-height: 1;
        color: #666;
    }
</style>
<style>
    .product-detail .content img {
        max-width: 100%;
        vertical-align: middle;
    }

    .product-detail .el-tabs--card>.el-tabs__header {
        border-bottom-width: 2px;
        border-color: #c7c7c7;
    }

    .product-detail .el-tabs__nav-wrap {
        margin-bottom: 0;
    }

    .product-detail .el-tabs--card>.el-tabs__header .el-tabs__nav {
        border-radius: 0;
        border-color: #c7c7c7;
    }

    .product-detail .el-tabs--card>.el-tabs__header .el-tabs__item {
        border-bottom: 1px solid #c7c7c7;
    }

    .product-detail .el-tabs__item.is-active {
        color: #666;
    }

    .product-detail .el-tabs__item.is-active::before {
        display: block;
        content: "";
        width: 100%;
        height: 4px;
        background: #34b8ff;
        position: absolute;
        left: 0;
        top: 0;
    }

    .product-detail .el-tabs__item.is-active::after {
        display: block;
        content: "";
        border: 3px solid;
        border-color: #34b8ff transparent transparent transparent;
        position: absolute;
        left: 50%;
        top: 4px;
        margin-left: -3px;
    }

    .product-detail .el-tabs__item {
        font-size: 16px;
        font-weight: bold;
    }
</style>