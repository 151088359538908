<template>
	<div class="wrap" :class="type == 2 ? 'video-page' : ''">
		<common-header></common-header>
		<div class="product" :style="'background: url(' + banner + ') no-repeat top center;'">
			<div class="main">
				<left-menu :list="cateList" :type="type"></left-menu>
				<div class="right-main">
					<router-view v-if="cateList.length" :key="key" :menu="cateList[0]" />
				</div>
			</div>
		</div>
		<black-footer></black-footer>
	</div>
</template>
<script>
	import CommonHeader from '@/components/CommonHeader';
	import BlackFooter from '@/components/BlackFooter';
	import LeftMenu from '@/components/LeftMenu';
	export default {
		name: 'ProductMain',
		components: {
			CommonHeader,
			BlackFooter,
			LeftMenu,
		},
		computed: {
			key() {
				return this.$route.path
			}
		},
		watch: {
			$route(to, from) {
				if (to.meta.type != from.meta.type) {
					this.type = this.$route.meta.type || '';
					this.cateList = [];
					this.pageInit();
				}
			}
		},
		data() {
			return {
				type: this.$route.meta.type || '',
				cateList: [],
				banner: ''
			}
		},
		mounted() {
			this.pageInit();
		},
		methods: {
			pageInit() {
				let type = this.type == 2 ? '2' : '1,3';
				this.$api.getCateData({ type }).then(res => {
					let { data, success } = res.data;
					if (success) {
						this.cateList = data;
					}
				});

				this.$api.getActivityData('PCBANNER').then(res => {
					let { data, success } = res.data;
					if (success) {
						if (this.type == 2) {
							this.banner = data.filter(item => item.model == 'BANNER_2')[0].img;
						}
						else {
							this.banner = data.filter(item => item.model == 'BANNER_1')[0].img;
						}
					}
				});
			},
		}
	}
</script>
<style scoped>
	.right-main {
		margin-left: 194px;
		padding-top: 321px;
		min-height: calc(100vh - 100px);
	}

	.video-page .right-main {
		padding-top: 432px;
	}
</style>