import { render, staticRenderFns } from "./Recommend.vue?vue&type=template&id=eb218edc&scoped=true&"
import script from "./Recommend.vue?vue&type=script&lang=js&"
export * from "./Recommend.vue?vue&type=script&lang=js&"
import style0 from "./Recommend.vue?vue&type=style&index=0&id=eb218edc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb218edc",
  null
  
)

export default component.exports