import Vue from 'vue'
import App from './App.vue'
import router from './router'
import api from './api'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

import { Toast } from 'vant';
import 'vant/lib/index.css';
Toast.setDefaultOptions({ forbidClick: true });

Vue.config.productionTip = false;

Vue.prototype.$api = api;
// Vue.prototype.$cv = cv;

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)  
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

Vue.prototype.isMobile = document.body.clientWidth < 1024;
new Vue({
  router,
  render: h => h(App),
}).$mount('#app');